import { METHOD, request } from "./../utils/request";
import { Establishments } from "./api";

async function getDetails(params) {
  return request(Establishments.getDetails, METHOD.GET, params);
}

async function update(data) {
  return request(Establishments.update, METHOD.POST, data);
}

export default {
  getDetails,
  update,
};
